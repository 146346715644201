import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Img1 from '../images/new/blog/post/image.jpeg'
import Img2 from '../images/new/blog/post/image-2.jpeg'
import Img3 from '../images/new/blog/post/image-3.jpeg'
import Img4 from '../images/new/blog/post/image-4.jpeg'

import { 
  section,
  container,
  content,
  column,
  columns
} from '../components/styles/Base.styles'


const BlogItem = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Jonas Ribeiro alegrou o dia na Kingdom School (unidade Kids)" />
    <NavBar />
    {/* <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div> */}
    <div css={[section, content]} style={{ paddingBottom: 0, marginTop: 112 }}>
      <div css={container}>
        <h1>Jonas Ribeiro alegrou o dia na Kingdom School (unidade Kids)</h1>
        <p>Quais as vantagens da leitura para as crianças? Imensas, pois cientificamente a leitura estimula o desenvolvimento cerebral, além de aumentar bastante o repertório e promover o enriquecimento da imaginação e criatividade dos estudantes.</p>
        <p>Muito mais vantajoso do que apenas ficar nas telinhas (smartfone, tablet e outros), as crianças, quando expostas a livros compatíveis à faixa etária, que estimulam a curiosidade, a socialização, o foco ou simplesmente momentos de encontros afetuosos, têm muito a ganhar em termos de crescimento e satisfação para apropriar o conhecimento.</p>
        <p>Em agosto/22, a Kingdom recebeu Jonas Ribeiro, um escritor que possui várias obras publicadas, que alegrou uma manhã e uma tarde das crianças que observaram e apreciaram muito a maneira peculiar desse escritor em expressar e interpretar os seus personagens.</p>
        <div css={columns}>
          <div css={column}>
            <img src={Img1} alt='' style={{ width: '100%' }} />
          </div>
          <div css={column}>
            <img src={Img4} alt='' style={{ width: '100%' }} />
          </div>
        </div>
        <p>Sendo assim, uma das estratégias usadas pela escola é diversificar o alcance às histórias para além do livro, usando o teatro, a música, o canto e o acesso a escritores, seja alguém da família das crianças, ou do meio literário. </p>
        <p>E Jonas fez isso muito bem, pois, de forma muito natural, várias crianças se aproximaram dele, fizeram perguntas e elogiaram as suas produções, querendo saber como ele conseguiu realizar os enredos.</p>
        <p>Como isso, vamos construindo hábitos saudáveis de leitura, além de tornar o livro útil para o crescimento dos estudantes junto aos temas abordados de acordo com a realidade da turma.</p>
        <div css={columns}>
          <div css={column}>
            <img src={Img2} alt='' style={{ width: '100%' }} />
          </div>
          <div css={column}>
            <img src={Img3} alt='' style={{ width: '100%' }} />
          </div>
        </div>
				<div style={{ paddingBottom: 112 }} />
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default BlogItem
